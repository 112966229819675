import deviceDetection from '@/common/device-detection';
import storage from '@/common/storage';
import audioTypes from '@/enums/audio-types';
import playerQualities from '@/enums/player-qualities';
import { PlayerMode } from '@/enums/PlayerMode';
import { AudioActions } from '@/store/audio/AudioActions';
import liveStore from '@/store/live-store.js';
import { MetadataActions } from '@/store/metadata/MetadataActions';
import { StoreOptions } from 'vuex';
import { LegacyLivePlayerGetter } from '@/enums/LegacyLivePlayerGetter';
import { LegacyLivePlayerAction } from '@/enums/LegacyLivePlayerAction';
import { PlayerContext } from '@/enums/PlayerContext';

export default {
    namespaced: true,
    state: {
        isVisible: false,
        isOverlayOpen: false,
        quality: playerQualities.MEDIUM,
        volume: 100,
        trackContext: PlayerContext.Site,
        scrollPosition: {
            currentPosition: 0,
            oldPosition: 0
        }
    },
    getters: {
        quality: state => state.quality,
        volume: state => state.volume,
        isVisible: state => state.isVisible,
        isOverlayOpen: state => state.isOverlayOpen,
        audioPlaying: (state, getters, rootState, rootGetters) => rootGetters[LegacyLivePlayerGetter.currentAudioKey],
        mode(state, getters, rootState: any, rootGetters) {
            if (rootState.player.live.isActive && rootGetters[LegacyLivePlayerGetter.isHlsEnabled]) {
                return PlayerMode.Hls;
            }
            if (rootState.player.live.isActive && !rootGetters[LegacyLivePlayerGetter.isHlsEnabled]) {
                return PlayerMode.Icecast;
            }

            return PlayerMode.Unknown;
        }
    },
    actions: {
        unload: ({ dispatch }) => dispatch(LegacyLivePlayerAction.Unload, null, { root: true }),
        closeOverlay: ({ commit }) => commit(Mutation.SetIsOverlayOpen, false),
        volumeUp: ({ dispatch, state }) => dispatch(InternalAction.SetAudioElementVolume, state.volume + 10),
        volumeDown: ({ dispatch, state }) => dispatch(InternalAction.SetAudioElementVolume, state.volume - 10),
        setQuality: ({ commit }, quality) => commit(Mutation.SetQuality, quality),
        deactivate: ({ dispatch }) => dispatch(LegacyLivePlayerAction.Deactivate, null, { root: true }),
        init({ commit, dispatch }, isEmbed: boolean) {
            if (isEmbed) {
                commit(Mutation.SetTrackContext, PlayerContext.Embed);
            }

            dispatch(LegacyLivePlayerAction.Init, null, { root: true });
        },
        load({ dispatch, commit }, audio: any) {
            dispatch(InternalAction.Deactivate);
            commit(Mutation.SetQuality, playerQualities.MEDIUM);
            if (isLiveType(audio.type)) {
                dispatch(LegacyLivePlayerAction.RestoreFromStorage, audio, { root: true });
            }
        },
        openOverlay({ commit }) {
            commit(Mutation.SetIsOverlayOpen, true);
        },
        play({ commit, dispatch }, audio: any) {
            dispatch(InternalAction.Deactivate);
            dispatch(MetadataActions.Clear, null, { root: true });
            dispatch(AudioActions.Pause, null, { root: true });
            commit(Mutation.SetIsVisible, true);
            if (isLiveType(audio.type)) {
                dispatch(LegacyLivePlayerAction.Play, audio, { root: true });
            }
        },
        playOrPause({ getters, dispatch }, audio: any) {
            const isPlaying =
                getters.audioPlaying &&
                getters.audioPlaying.id.toString() === audio.id.toString() &&
                getters.audioPlaying.type === audio.type;

            if (isPlaying) {
                dispatch(InternalAction.Pause);
            } else {
                dispatch(InternalAction.Play, { id: audio.id, type: audio.type });
            }
        },
        pause({ commit, dispatch }) {
            commit(Mutation.SetIsVisible, true);
            dispatch(LegacyLivePlayerAction.Pause, null, { root: true });
        },
        volume({ dispatch }, volume) {
            dispatch(InternalAction.SetVolume, volume);
            dispatch(InternalAction.SetAudioElementVolume, volume);
        },
        setVolume({ commit }, volume) {
            commit(Mutation.SetVolume, volume);
            storage.volume = volume;
        },
        setAudioElementVolume({ dispatch }, volume) {
            if (volume === undefined) {
                volume = 100;
            }
            if (volume > 100) {
                volume = 100;
            }
            if (volume < 0) {
                volume = 0;
            }
            dispatch(LegacyLivePlayerAction.SetVolume, volume, { root: true });
        },
        setVisibilityOnScroll({ commit, state }, currentScrollPosition: number) {
            commit(Mutation.SetScrollPosition, currentScrollPosition);

            if (state.isOverlayOpen) {
                return;
            }

            const isScrollingUp = state.scrollPosition.currentPosition < state.scrollPosition.oldPosition;
            const isScrollingDown = state.scrollPosition.currentPosition > state.scrollPosition.oldPosition;

            if (isScrollingDown && state.isVisible) {
                commit(Mutation.SetIsVisible, false);
            }

            if (isScrollingUp && !state.isVisible) {
                commit(Mutation.SetIsVisible, true);
            }
        },
        async changeQuality({ commit, dispatch }, quality) {
            commit(Mutation.SetQuality, quality);
            await dispatch(LegacyLivePlayerAction.OnQualityChangeAsync, null, { root: true });
            storage.quality = quality;
        }
    },
    mutations: {
        setTrackContext: (state, trackContext: string) => (state.trackContext = trackContext),
        setIsVisible: (state, isVisible: boolean) => (state.isVisible = isVisible),
        setIsOverlayOpen: (state, isOverlayOpen: boolean) => (state.isOverlayOpen = isOverlayOpen),
        setVolume: (state, volume: number) => (state.volume = volume),
        setScrollPosition(state, currentScrollPosition: number) {
            if (currentScrollPosition < 0) {
                currentScrollPosition = 0;
            }

            state.scrollPosition.oldPosition = state.scrollPosition.currentPosition;
            state.scrollPosition.currentPosition = currentScrollPosition;
        },
        setQuality(state, quality: string) {
            if (quality === playerQualities.LOW || quality === playerQualities.MEDIUM || quality === playerQualities.HIGH) {
                state.quality = quality;
            } else if (deviceDetection.isSmartphoneOrTablet) {
                state.quality = playerQualities.MEDIUM;
            } else {
                state.quality = playerQualities.HIGH;
            }
        }
    },
    modules: {
        live: liveStore
    }
} as StoreOptions<State>;

interface State {
    isVisible: boolean;
    quality: string;
    isOverlayOpen: boolean;
    scrollPosition: ScrollPosition;
    volume: number;
    trackContext: string;
}

interface ScrollPosition {
    currentPosition: number;
    oldPosition: number;
}

function isLiveType(audioType: any): boolean {
    return audioType === audioTypes.CHANNEL || audioType === audioTypes.MUSIC || audioType === audioTypes.EXTRA;
}

enum Mutation {
    SetTrackContext = 'setTrackContext',
    SetIsVisible = 'setIsVisible',
    SetIsOverlayOpen = 'setIsOverlayOpen',
    SetVolume = 'setVolume',
    SetScrollPosition = 'setScrollPosition',
    SetQuality = 'setQuality'
}

enum InternalAction {
    SetAudioElementVolume = 'setAudioElementVolume',
    Deactivate = 'deactivate',
    Pause = 'pause',
    Play = 'play',
    SetQuality = 'setQuality',
    SetVolume = 'setVolume'
}
